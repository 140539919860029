import React, { useState } from 'react'

function SelectStatus({ name ,setStatus, defaultValue}) {
    



    // const value = defaultValue !== '' ? defaultValue : "Select Status"
    const [isRejected, setRejected] = useState(false);
    const [status, setStatusValue] = useState(defaultValue ? defaultValue : "Select Status");
    const selectHandel = (e) => {
        e.preventDefault();
        console.log(status)
        setStatusValue(e.target.value)
        if (e.target.value === 'REJECTED') {
            setRejected(true);
        } else {
            setRejected(false);
        }
    }
    setStatus(status)
  return (
    <div className='w-full'>
    <select name={name} id={name}  className='w-full' onChange={(e) => selectHandel(e)}>
            <option value={status}>{ status}</option>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="CLOSED">Closed</option>
            <option value="REJECTED">Rejected</option>    
    </select>
    {
        isRejected &&
        <div>
        <label>Rejected Reason</label>
        <input className='w-full' type="text" name="rejected" />
        </div>
    }
</div>  
  )
}

export default SelectStatus