// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list_b_container{
    background-color: var(--bgSoft);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .list_b_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .list_b_addButton{
    padding: 10px;
    background-color: #5d57c9;
    color: var(--text);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .list_b_table{
    width: 100%;
  }
  
  .list_b_table td{
    padding: 10px;
  }
  
  .list_b_user{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .list_b_userImage{
    border-radius: 50%;
    object-fit: cover;
  }
  
  .list_b_buttons{
    display: flex;
    gap: 10px;
  }
  
  .list_b_button{
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--text);
    border: none;
    cursor: pointer;
  }
  
  .list_b_view{
    background-color: teal;
  }
  
  .list_b_delete{
    background-color: crimson;
  }`, "",{"version":3,"sources":["webpack://./src/pages/bussiness/listbusiness/listbusiness.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".list_b_container{\n    background-color: var(--bgSoft);\n    padding: 20px;\n    border-radius: 10px;\n    margin-top: 20px;\n  }\n  \n  .list_b_top{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .list_b_addButton{\n    padding: 10px;\n    background-color: #5d57c9;\n    color: var(--text);\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .list_b_table{\n    width: 100%;\n  }\n  \n  .list_b_table td{\n    padding: 10px;\n  }\n  \n  .list_b_user{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n  }\n  \n  .list_b_userImage{\n    border-radius: 50%;\n    object-fit: cover;\n  }\n  \n  .list_b_buttons{\n    display: flex;\n    gap: 10px;\n  }\n  \n  .list_b_button{\n    padding: 5px 10px;\n    border-radius: 5px;\n    color: var(--text);\n    border: none;\n    cursor: pointer;\n  }\n  \n  .list_b_view{\n    background-color: teal;\n  }\n  \n  .list_b_delete{\n    background-color: crimson;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
