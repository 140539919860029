import React from 'react'
import Layout from '../components/layout'

function DashboardPage() {
  return (
      <Layout>
          Dashboard Under construction ....
    </Layout>
  )
}

export default DashboardPage