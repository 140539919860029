import { AUTHORITY_LIST_FAILURE, AUTHORITY_LIST_REQUEST, AUTHORITY_LIST_SUCCESS, DMO_LIST_FAILURE, DMO_LIST_REQUEST, DMO_LIST_SUCCESS } from "../constant/dmo";

export const dmoReducer = (state = {dmo:[], loading:true, error:null}, action) => {
    switch (action.type) {
      case DMO_LIST_REQUEST:
        return {
          loading: true,
        };
      case DMO_LIST_SUCCESS:
        return {
          loading: false,
          dmo: action.payload,
        };
  
      case DMO_LIST_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
};
  
export const authorityReducer = (state = {authority:[], loading:true, error:null}, action) => {
  switch (action.type) {
    case AUTHORITY_LIST_REQUEST:
      return {
        loading: true,
      };
    case AUTHORITY_LIST_SUCCESS:
      return {
        loading: false,
        authority: action.payload,
      };

    case AUTHORITY_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};