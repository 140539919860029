import React from 'react'
import SideBar from './sidebar/sideBar'
import './layout.css'
import NavBar from './navbar/navbar'

function Layout({children}) {
  return (
    <div className='containers'>
    <div className='sidebars'>
        <SideBar />
    </div>
    <div className='mains'>
       <NavBar/>
        {children}
    </div>
</div>
  )
}

export default Layout