import React, { useState } from 'react'
import {Document, Page} from 'react-pdf'

import privacy from "../assets/privacy.pdf"
function PrivacyPolicy() {
    const [numPages, setNumber] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentloadSucces({ numPages }) {
        console.log(numPages)
        setNumber(numPages);
    }
  return (
      <div className='flex flex-col justify-center items-center rounded-lg'>
          <Document file={privacy } onLoadSuccess={onDocumentloadSucces} className="overflow-scroll">
              {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
          <>
              Page {pageNumber} of {numPages}
          </>
    </div>
  )
}

export default PrivacyPolicy