
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PrivateRoutes from './utils/PrivateRoutes.js';
import LoginPage from './pages/login';
import ProductPage from './pages/product/product';
import DashboardPage from './pages/dashboard';
import UserPage from './pages/users/user';
import AddBusinessPage from './pages/bussiness/addbusiness';
import PrivacyPolicy from './pages/privacyPolicy';
import { pdfjs } from 'react-pdf';
import BusinessPage from './pages/bussiness/listbusiness/business';
import BusinessDetails from './pages/bussiness/businessdetails/bussinessdetails';
import AddProductPage from './pages/product/addProduct/addProduct';
import Terms from './pages/terms&condition';
import ProductDetails from './pages/product/productDetails/productDetails';
import Payment from './pages/payment';
import Test from './pages/test';
import VendorPrivacyPolicy from './pages/vendorT&C';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
            <Route element={<DashboardPage />} path="/" exact />
            <Route element={<ProductPage/>} path="/dashboard/products" exact/>
            <Route element={<BusinessPage />} path="/dashboard/business" exact />
            <Route element={<UserPage/>} path="/dashboard/users" exact/>
            <Route element={<AddBusinessPage />} path="/dashboard/business/add" exact />
            <Route element={<BusinessDetails />} path="/dashboard/business/:id" exact />
            <Route element={<ProductDetails />} path="/dashboard/business/product/:id" exact />
            <Route element={<AddProductPage/>} path="/dashboard/product/add/:id" exact/>
            </Route>
          <Route element={<LoginPage />} path="/login" />
          <Route element={<PrivacyPolicy />} path='privacy&policy' />
          <Route element={<VendorPrivacyPolicy />} path='vendorsTerms&Conditions' />
          <Route element={<Terms />} path='terms&conditions' />
          <Route element={<Payment />} path="/payment" exact />
          <Route element={<Test/>} path="/test" exact/>
          
          </Routes>
      </Router>
    </div>
  );
}

export default App;


