import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/layout';
import SelectDMO from '../../../components/selectDMO/selectdmo'
import SelectStatus from '../../../components/status/selectstatus'
import {
    MdArrowDropDown,
    MdArrowDropUp,
    MdCloudUpload
} from 'react-icons/md'
import Categories from '../../../components/categoires/categories';
import GroupType from '../../../components/groupType/groupType';
import Accessibility from '../../../components/accessibilities/accessibilities';
import { useForm } from 'react-hook-form';
import { fetchBusinessDetails, updateBusiness } from '../../../store/action/business';
import ImageSlider from '../../../components/imageSlider/imageSlider';
import Swal from 'sweetalert2';
import { UPDATE_BUSI_RESET } from '../../../store/constant/business';
import ProductCard from '../../../components/productCard/productCard';
import { fetchProductList } from '../../../store/action/product';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { fetchAllSubCategory } from '../../../store/action/categories';
import axios from 'axios';


const MAPBOXURL = process.env.REACT_APP_MAPBOX_SEARCH
const token = `${process.env.REACT_APP_MAPBOX_ACCESS}`;
function BusinessDetails() {

    const busDetails = useSelector((state) => state.businessDetails);
    const { loading, error, busiDetails } = busDetails;
    const pathname = window.location.pathname;
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [hideCat, setHideCat] = useState(true)
    const [hideGroupType, setHideGroupType] = useState(true)
    const [hideAcc, setHideAcc] = useState(true)
    const [hideProd, setHideProd] = useState(true)
    const [authority, setAuthority] = useState();
    const [status, setStatus] = useState();
    const [groupTypesId, setgroupTypesId] = useState([]);
    const [accessibilitiesId, setaccessibilitiesId] = useState([]);
    const [bussCategoriesId, setbussCategoriesId] = useState([]);
    const [bussSubCategoriesId, setbussSubCategoriesId] = useState([]);

    const [groupTypesCheck, setGroupTypesCheck] = useState([]);
    const [accessibilitiesCheck, setaccessibilitiesCheck] = useState( []);
    const [bussCategoriesCheck, setbussCategoriesCheck] = useState([]);
    const [bussSubCategoriesCheck, setbussSubCategoriesCheck] = useState([]);
    const [authorityCheck, setAuthorityCheck] = useState('');
    const [statusCheck, setStatusCheck] = useState('');
    const [images, setImages] = useState('');
    const dispatch = useDispatch()
    const update = useSelector((state) => state.updateBusiness);
    const { loading: up_loading, error: up_error, success } = update;
    
    const allSubCat = useSelector((state) => state.allSubCategoryList);
    const { allSubCategory } = allSubCat;
    // const { loading:cat_loading, error:cat_error, category } = cat;

    const product = useSelector((state) => state.productsList);
    const { loading:p_loading, error:p_error, products } = product;

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm();

const id = pathname.split('/').pop()
    useEffect(() => {
        dispatch(fetchAllSubCategory());
        if (loading) {
            dispatch(fetchBusinessDetails(pathname.split('/').pop()))
        }
        if (p_loading) {
            dispatch(fetchProductList(pathname.split('/').pop()))
        }
        setGroupTypesCheck(
            busiDetails?.groupTypesId?.map(items => (items._id)) 
        )
        setaccessibilitiesCheck(
            busiDetails?.accessibilitiesId?.map(items => (items._id))
        )
        setbussSubCategoriesCheck(
            busiDetails?.bussSubCategoriesId?.map(items => (items._id))
        )
        setAuthorityCheck(
            busiDetails?.authorityId?._id
        )
        setStatusCheck(
            busiDetails?.status
        )
        setPhone(
            busiDetails?.phoneNumber
        )
        setCountryCode(
            busiDetails?.countryCode
        )
        setContactAddress(
            busiDetails?.contactDetails?.address
        )
        setCountry(
            busiDetails?.address?.country
        )
        setCity(
            busiDetails?.address?.city
        )
        setLocation(
            busiDetails?.address?.location
        )
        setGeoLocation({
            lat: busiDetails?.geoLocation?.lat,
            long: busiDetails?.geoLocation?.long
        }
        )
        if (allSubCategory && bussSubCategoriesId?.length > 0) {
            const cat = allSubCategory.filter(cat => {
              if(bussSubCategoriesId.includes(cat._id))  return cat
            })
            const cats = cat.map(item => {
              return item.bussCategoryId[0]._id
            })
            let outputArray = Array.from(new Set(cats))
            setbussCategoriesId(outputArray)
        }
       
            
    }, [dispatch, busiDetails, bussSubCategoriesId])
 
    const addBusiness = ({id, name, contactName, busiEmail, description, additional_information}) => {
        const address = {
            country:country,
            city:city, 
            location:location,
            additional_information:additional_information
        }
        const contactDetails = {
            name: contactName,
            address: contactAddress,
            countryCode: `${countryCode}`,
            phone:phone.includes(`${countryCode}`) ? phone.split(`${countryCode}`)[1] :phone
        }
        dispatch(updateBusiness(
            id,
            name,
            phone,
            countryCode,
            contactDetails,
            busiEmail,
            address,
            description,
            authority,
            geoLocation,
            status,
            groupTypesId,
            accessibilitiesId,
            bussCategoriesId,
            bussSubCategoriesId,
        ));
    }
    useEffect(() => {
        if (success) {
            Swal.fire("Successfull!", "Bussiness was successfully Updated", "success");
            dispatch({
                type: UPDATE_BUSI_RESET,
            });
        }
    }, [dispatch, success])
    
    const handlePhone = (phone, country) => {
        const dialCode = country.dialCode;
        setPhone(phone.split(dialCode)[1])
        setCountryCode(`+${dialCode}`);
    
    }
    const [contactAddress, setContactAddress] = useState('');
    const [suggestPlace, setSuggestPlace] = useState([])
    const [country, setCountry] = useState()
    const [geoLocation, setGeoLocation] = useState({})
    const [city, setCity] = useState()
    const [location, setLocation] = useState();
    let suggestDiv = document.querySelectorAll(".suggestDisplay");
    const searchValue = async (value) => {
        setContactAddress(value);
        suggestDiv[0].style.display = 'block';
        const result = await axios.get(`${MAPBOXURL}${value}.json?access_token=${token}&cachebuster=1566806258853&autocomplete=true&limit=5`)
        setSuggestPlace(result?.data?.features)
    }
    const addContactAddress = (suggest) => {
        setContactAddress(suggest?.place_name);
        suggest?.context.map((context) => {
                if (context.id.includes('country')) {
                    setCountry(context.text);
                }
 
            });
        
        if ( suggestDiv.length !== 0) {
            suggestDiv[0].style.display = 'none';
        } 
        setGeoLocation({
            lat: suggest?.center[1],
            long:suggest?.center[0]
        })

      }
  return (
      <Layout>
          {
              loading ? <span>loading...</span> : error ? '':
         groupTypesCheck === undefined ? '' :
        <div className='addcontainers'>
            <div>
                <div className='infoContainer'>
                    <div className='imgContainer'>
                        {/* <div className='tooltip cursor-pointer'>
                        <input type="file" className="filetype cursor-pointer" id="browse" ></input>
                        <div className='tooltiptext'>
                            Upload Image    
                        </div>       
                       </div> */}
                                      {/* <img alt="preview" src={sendFile}/> */}
                                      <ImageSlider
                                          image={busiDetails?.images}
                                          setImages={setImages}
                                          update={true}
                                          purpose={"Business"}
                                          id={id}
                                      />
                        {
                            busiDetails?.images.map(imgs => (
                                <img alt="preview" src={imgs.image}/>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <div className=
                    {
                        hideCat ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                    } >
                    <div className='flex justify-between items-center'>
                        <span>Categoires and Sub Categoires</span>
                        <span  className='cursor-pointer'>
                        {
                        hideCat ? 
                        <MdArrowDropDown onClick={()=>setHideCat(false)} style={{fontSize:'25px'}}/>
                        :       
                        <span>
                        <MdArrowDropUp onClick={()=>setHideCat(true)} style={{fontSize:'25px'}}/>
                        </span>
                    }
                        
                        </span>
                    </div>
                        {
                            loading ?
                            <span>{error}</span>
                            : error ?
                            <span>{error}</span>
                            :
                        <Categories
                            cats={[]}
                            checkValue={[]}
                            subCategoryCheck={bussSubCategoriesCheck}
                            allCategory={[]}
                            setbussCategoriesId={setbussCategoriesId}
                            setbussSubCategoriesId={setbussSubCategoriesId}
                        ></Categories>
                        }
                    <div>     
                </div>
                    
                    </div>
                    <div className={
                            hideGroupType ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                        }>
                        <div className='flex justify-between items-center'>
                            <span>Group Type</span>
                            <span  className='cursor-pointer'>
                                {
                                    hideGroupType ? 
                                <MdArrowDropDown onClick={()=>setHideGroupType(false)} style={{fontSize:'25px'}}/>
                                :       
                                <span>
                                <MdArrowDropUp onClick={()=>setHideGroupType(true)} style={{fontSize:'25px'}}/>
                                </span>
                                }
                            </span>
                        </div>
                        <div>
                            <GroupType check={groupTypesCheck} setgroupTypesId={setgroupTypesId}/>
                        </div>
                    </div> 
                    <div className={
                            hideAcc ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                        }>
                        <div className='flex justify-between items-center'>
                            <span>Accessibility</span>
                            <span  className='cursor-pointer'>
                                {
                                    hideAcc ? 
                                <MdArrowDropDown onClick={()=>setHideAcc(false)} style={{fontSize:'25px'}}/>
                                :       
                                <span>
                                <MdArrowDropUp onClick={()=>setHideAcc(true)} style={{fontSize:'25px'}}/>
                                </span>
                                }
                            </span>
                        </div>
                        <div>
                            <Accessibility setaccessibilitiesId={setaccessibilitiesId}  check={accessibilitiesCheck}/>
                        </div>
                    </div> 
                </div>
                <a href={`/dashboard/product/add/${busiDetails?._id}`}>
                              <div className='infoContainer m-1 cursor-pointer'>
                    Add product
                </div>
                
               </a>
               <div className={
                            hideProd ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                        }>
                        <div className='flex justify-between items-center'>
                            <span>Products</span>
                            <span  className='cursor-pointer'>
                                {
                                    hideProd ? 
                                <MdArrowDropDown onClick={()=>setHideProd(false)} style={{fontSize:'25px'}}/>
                                :       
                                <span>
                                <MdArrowDropUp onClick={()=>setHideProd(true)} style={{fontSize:'25px'}}/>
                                </span>
                                }
                            </span>
                        </div>
                            {
                                p_loading ? <span>Loading...</span> : p_error ? <span>{p_error}</span> :
                                <ProductCard products={products}/>
                            }
                        
                    </div> 
            </div>
            <div className='formContainer'>
                <form  className='form' onSubmit={handleSubmit(addBusiness)}>
                    <input hidden type="text" name="id" defaultValue={busiDetails?._id}
                        {...register("id")}
                        
                    />
                    <span className='flex'>
                    <label>Name</label> <span className="text-red-500 text-sm">*</span>
                    </span>
                    <input type="text" name="name" defaultValue={busiDetails?.name}
                        {...register("name", {
                    required: "Business Name is required",
                        })}
                        
                    />
                    {errors.name && (
                <div className="text-red-500">
                    {errors.name.message}
                        </div>
                        
                    )}
                    <span className='flex'>
                    <label>Business Email</label> <span className="text-red-500 text-sm">*</span>
                    </span>
                    <input
                        type="email"
                        name="busiEmail" 
                        defaultValue={busiDetails?.busiEmail}
                        {...register("busiEmail", {
                    required: "Business Email is required",
                        })}
                    />
                    {errors.busiEmail && (
                <div className="text-red-500">
                    {errors.busiEmail.message}
                        </div>
                        
                    )}
                    <span className='flex'>
                      <label>Contact Name</label> <span className="text-red-500 text-sm">*</span>
                    </span>
                      <input
                          type="text"
                        name="contactName" 
                        defaultValue={busiDetails?.contactDetails?.name}
                          {...register("contactName", {
                    required: "Contact Name is required",
                          })}
                      />
                      {errors.contactName && (
                  <div className="text-red-500">
                    {errors.contactName.message}
                          </div>
                          
                                  )}
                                  <span className='flex'>
                      <label>Contact Address</label> <span className="text-red-500 text-sm">*</span>
                    </span>
                      <input
                          type="text"
                          value={contactAddress}
                          onChange={(e) => searchValue(e.target.value)}
                          name="contactAddress" 
                    //       {...register("contactAddress", {
                    // required: "Contact address is required",
                    //       })}
                      />
                      {errors.contactAddress && (
                  <div className="text-red-500">
                    {errors.contactAddress.message}
                                      </div>
                                      
                          
                                  )}
                <div className='suggestDisplay bg-white rounded-xl text-black'>
                {
                  suggestPlace?.map((suggest) => (
                    <div className='pt-2'>
                      <div className='shadow cursor-pointer p-1' onClick={() => addContactAddress(suggest)}>
                      {suggest?.place_name}
                      </div>
                    </div>
                  ))
                }
          </div>
                    <label>Phone Number</label>
                        {/* <input type="text" name="phoneNumber"
                        defaultValue={busiDetails?.phoneNumber}
                            {...register("phoneNumber", {
                    required: "Phone Number is required",
                    })} />
                    {errors.phoneNumber && (
                <div className="text-red-500">
                    {errors.phoneNumber.message}
                        </div>
                        
                    )} */}
                                  <PhoneInput
                                      inputProps={{
                                          name: 'phone',
                                          required: true,
                                      }}
                    style={{color:'black', bgColor:"#151c2c"}}
                      enableSearch={true}
                    value={busiDetails?.countryCode+busiDetails?.phoneNumber}
                        onChange={(phone, country) => {
                            handlePhone(phone, country)

                        }}
                    />
                      {(phone === "" || phone === undefined) && (
                  <div className="text-red-500">
                    Phone number is required
                          </div>
                          
                      )}
                    <label>Country</label>
                    <input type="text" name="country" value={country} onChange={(e)=> setCountry(e.target.value)} />
                    <label>City</label>
                                  <input type="text" name="city" value={city} onChange={(e)=> setCity(e.target.value) }/>
                    <label>location</label>
                    <input type="text" name="location" value={location} onChange={(e)=> setLocation(e.target.value)} />
                    <label>Additional Information</label>
                    <input type="text" name="additional_information" defaultValue={busiDetails?.address?.additional_information}  {...register("additional_information")}/>
                    <label>Description</label>
                    <textarea type="textarea" name="description" defaultValue={busiDetails?.description} {...register("description", {
                    required: "Description  is required",
                    })} />
                    {errors.description && (
                <div className="text-red-500">
                    {errors.description.message}
                        </div>
                        
                    )}
                    <label>Status</label>
                    <SelectStatus name="status" defaultValue={statusCheck} setStatus={setStatus}  ></SelectStatus> 
                    {errors.status && (
                <div className="text-red-500">
                    {errors.status.message}
                        </div>
                        
                    )}
                    <label>Select DMO</label>
                    <SelectDMO setAuthority={setAuthority} defaultValue={authorityCheck} name="authority"  {...register("authority")} /> 
                    {errors.authority && (
                <div className="text-red-500">
                    {errors.authority.message}
                        </div>
                        
            )}
            <div>
            {up_loading &&<span className='text-green-500'>Loading...</span>}
            { up_error && <span className='text-red-500'>{up_error}</span>}
            </div>
                <input
                    type="submit"
                    value="Update Business"
                    className="
                        w-full
                        rounded-md
                        border
                        py-3
                        text-base 
                        cursor-pointer
                        transition"/>
                </form>
            </div>
        </div>
    }
</Layout>
  )
}

export default BusinessDetails