import axiosInterceptorInstance from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { ACCESSIBILITIES_LIST_FAILURE, ACCESSIBILITIES_LIST_REQUEST, ACCESSIBILITIES_LIST_SUCCESS } from "../constant/accessibilities";

export const fetchAccessibilities = () => async (dispatch) => {

    dispatch({
        type: ACCESSIBILITIES_LIST_REQUEST,

      });
    try {
        const {data} = await axiosInterceptorInstance.get('/admin/accessibility?locale=he')
        dispatch({
            type: ACCESSIBILITIES_LIST_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: ACCESSIBILITIES_LIST_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}