import React, { useEffect, useState,  } from 'react'
import { login } from '../store/action/auth.action';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getAccessToken } from '../auth/auth.helpers';

function LoginPage() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('biabsh');
  const [error, setError] = useState('');

  const signIn = useSelector((state) => state.signIn);
  const { loading, error: loginError, userInfo } = signIn;
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const loginFunction = async (e)=> {
    e.preventDefault();
    if (email === '') {
      setError("Email is required")
    }
    if (password === '') {
      setError("Password is required")
    }
     dispatch(login(email, password));
    
  }
  const token = getAccessToken();

  useEffect(() => {
    if (userInfo || token) {
      navigate('/')
    }
  },[navigate, userInfo, token])
  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      {/* <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"/> */}
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
    </div>
  
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={(e) => loginFunction(e)}>
          <div>
          <div className="text-sm w-full">
              {
                error && 
                <span className='bg-red-400 text-white'>
                {error}
                </span>
              }
              {
                loginError &&
                <span className='bg-red-400 text-white p-2 rounded-sm text-lg w-full'>
                {loginError}
                </span>
              }
              </div>
          </div>
          <div className='flex flex-col flex-start'>
            <div className="flex items-center justify-between">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
          </div>
          <div className="mt-2">
            <input 
              onChange={(e) => setEmail(e.target.value)}
              id="email" name="email"
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
          </div>
          <div className="mt-2">
            <input onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
          </div>
        </div>
          {
            loading && 
            <span>
                loading..
            </span>
              }
        <div>
          <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 hover:bg-indigo-500 px-3 py-1.5 text-sm font-semibold  text-white">Sign in</button>
        </div>
      </form>
    </div>
  </div>
  )
}

export default LoginPage