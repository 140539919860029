export const LIST_PROD_REQUEST = "LIST_PROD_REQUEST";
export const LIST_PROD_SUCCESS = "LIST_PROD_SUCCESS";
export const LIST_PROD_FAILURE = "LIST_PROD_FAILURE";

export const DETAIL_PROD_REQUEST = "DETAIL_PROD_REQUEST";
export const DETAIL_PROD_SUCCESS = "DETAIL_PROD_SUCCESS";
export const DETAIL_PROD_FAILURE = "DETAIL_PROD_FAILURE";


export const UPDATE_PROD_REQUEST = "UPDATE_PROD_REQUEST";
export const UPDATE_PROD_SUCCESS = "UPDATE_PROD_SUCCESS";
export const UPDATE_PROD_FAILURE = "UPDATE_PROD_FAILURE";
export const UPDATE_PROD_RESET = "UPDATE_PROD_RESET";


export const ADD_PROD_REQUEST = "ADD_PROD_REQUEST";
export const ADD_PROD_SUCCESS = "ADD_PROD_SUCCESS";
export const ADD_PROD_FAILURE = "ADD_PROD_FAILURE";
export const ADD_PROD_RESET = "ADD_PROD_RESET";