import React, { useEffect, useState } from 'react'

import {useDispatch, useSelector} from 'react-redux'
import { fetchCategory, fetchSubCategory } from '../../store/action/categories'
import SubCategoires from '../subccategoires/SubCategoires'

function Categories({ cats, checkValue, subCategoryCheck, allCategory, setbussCategoriesId, setbussSubCategoriesId }) {
    
    const [subCatData, setSubCatgeory] = useState([])
    const [subCategoires, setSubCategoires] = useState([]);
    const subCat = useSelector(state => state.subCategoryList)
    const { loading, error, subCategory } = subCat
    
    const cat = useSelector((state) => state.categoriesList);
    const { loading:cat_loading, error:cat_error, category } = cat;
    const dispatch = useDispatch()
    const listSubcategories = (id) => {
        dispatch(fetchSubCategory([id]))
    }

    useEffect(() => {

        if(cat_loading){
            dispatch(fetchCategory())
        };
        if (subCategory) {
            setSubCatgeory(subCategory)
      } 
    }, [dispatch, cat_loading, subCategory])

    console.log("subCategoires",subCategoires);
    
    setbussSubCategoriesId(subCategoires);
  return (
      <div className='flex flex-col '> 
          {
            cat_loading ? <span>Loading...</span>
            :
                  cat_error ? <span>{cat_error} </span> :
                  <div className='flex justify-between items-center overflow-x-scroll'>
            {
                category.map((cat) => (
                    <div key={cat._id} className='flex flex-col justify-center items-center p-2 cursor-pointer'
                            onClick={() => listSubcategories(cat._id)}>
                        <img
                        src={cat.icon.iconPath}
                            alt={cat.name}
                            style={{
                                width: '20px',
                                height:'20px'
                            }}
                        
                        ></img>
                        <span>
                            {
                            allCategory?.includes(cat._id) ?
                                <span className='bg-gray-100'>  {cat.name}</span>
                                :<span>  {cat.name}</span>
                            }
                    </span>
                    </div>
                ))
            }
        </div>
          }
        
        {
            loading &&
            <span>Loading..</span>
        }
        {
            error &&
            <span>{error}</span>
        } 
        <div>
                <div className="m-2 flex">
                    <SubCategoires
                        subCat={subCatData}
                        check={subCategoryCheck}
                        allSubCat={subCategoryCheck}
                        allCat={allCategory}
                        setSubCategoires={setSubCategoires}
                    ></SubCategoires>
                </div>
           
            
        </div>
    </div>
  )
}

export default Categories