import axiosInterceptorInstance, { getAccessToken } from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { ADD_BUSI_FAILURE, ADD_BUSI_REQUEST, ADD_BUSI_SUCCESS, DETAIL_BUSI_FAILURE, DETAIL_BUSI_REQUEST, DETAIL_BUSI_SUCCESS, LIST_BUSI_FAILURE, LIST_BUSI_REQUEST, LIST_BUSI_SUCCESS, UPDATE_BUSI_FAILURE, UPDATE_BUSI_REQUEST, UPDATE_BUSI_SUCCESS } from "../constant/business";

export const addNewBusiness = (
    name,
    phoneNumber,
    countryCode,
    contactDetails,
    busiEmail,
    address,
    description,
    authority,
    geoLocation,
    status,
    groupTypesId,
    accessibilitiesId,
    bussCategoriesId,
    bussSubCategoriesId,
    images
) => async (dispatch) => {

    dispatch({
        type: ADD_BUSI_REQUEST,
        payload: {
            name,
            phoneNumber,
            countryCode,
            contactDetails,
            busiEmail,
            address,
            description,
            authority,
            geoLocation,
            status,
            groupTypesId,
            accessibilitiesId,
            bussCategoriesId,
            bussSubCategoriesId,
            images
        }
      });
    try {
        console.log('image add', images)
        let formData = new FormData();
        // formData.append('files', images);
        for (var im = 0; im < images.length; im++) {
            formData.append('image', images[im]);
            console.log("image add",images[im])
        }
        formData.append("name", name);
        formData.append("countryCode", countryCode);
        formData.append("phoneNumber", phoneNumber);
        formData.append("busiEmail", busiEmail);
        formData.append('geoLocation[lat]', geoLocation.lat)
        formData.append('geoLocation[long]', geoLocation.long)
        formData.append("address[country]", address.country);
        formData.append("address[location]", address.location);
        formData.append("address[city]", address.city);
        formData.append("address[additional_information]", address.additional_information);
        formData.append('contactDetails[name]', contactDetails.name);
        formData.append('contactDetails[phone]', contactDetails.phone);
        formData.append('contactDetails[countryCode]', contactDetails.countryCode);
        formData.append('contactDetails[address]', contactDetails.address);
        formData.append("description", description);
        formData.append("authorityId", authority);
        for (var i = 0; i < groupTypesId?.length; i++) {
            formData.append('groupTypesId[]', groupTypesId[i]);
        }
        for (var j = 0; j < accessibilitiesId.length; j++) {
            formData.append('accessibilitiesId[]', accessibilitiesId[j]);
        }
        for (var k = 0; k < bussCategoriesId.length; k++) {
            formData.append('bussCategoriesId[]', bussCategoriesId[k]);
        }
        for (var l = 0; l < bussSubCategoriesId.length; l++) {
            formData.append('bussSubCategoriesId[]', bussSubCategoriesId[l]);
        }
        formData.append("status", status);


        const { data } = await axiosInterceptorInstance.post('/admin/business', formData,
     {
            headers: {
                'Authorization': getAccessToken(),
                "Content-Type": "multipart/form-data",
                "type": "formData"
            }
        })
        dispatch({
            type: ADD_BUSI_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: ADD_BUSI_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const updateBusiness = (
    id,
    name,
    phoneNumber,
    countryCode,
    contactDetails,
    busiEmail,
    address,
    description,
    authority,
    geoLocation,
    status,
    groupTypesId,
    accessibilitiesId,
    bussCategoriesId,
    bussSubCategoriesId,
) => async (dispatch) => {

    dispatch({
        type: UPDATE_BUSI_REQUEST,
        payload: {
            id,
            name,
            phoneNumber,
            busiEmail,
            address,
            description,
            authority,
            geoLocation,
            status,
            groupTypesId,
            accessibilitiesId,
            bussCategoriesId,
            bussSubCategoriesId,
        }
      });
    try {
        const { data } = await axiosInterceptorInstance.put(`/admin/business/${id}`, {
            name,
            phoneNumber,
            countryCode,
            contactDetails,
            busiEmail,
            address,
            description,
            authorityId: authority,
            geoLocation,
            status,
            groupTypesId,
            accessibilitiesId,
            bussCategoriesId,
            bussSubCategoriesId,
        },
        {
            headers: {
                'Authorization': getAccessToken(),
                "Content-Type": "application/json",
            }
        })
        dispatch({
            type: UPDATE_BUSI_SUCCESS,
            payload: data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: UPDATE_BUSI_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const fetchBusiness = (page, search) => async (dispatch) => {

    dispatch({
        type: LIST_BUSI_REQUEST,

      });
    try {
        const { data } = await axiosInterceptorInstance.get(
            `/admin/businesses?page=${page}&search=${search}`,
            {
            headers: {
                'Authorization': getAccessToken(),
                "Content-Type": "application/json",
            }
        })
        console.log(data);
        dispatch({
            type: LIST_BUSI_SUCCESS,
            payload: data?.datas,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: LIST_BUSI_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const fetchBusinessDetails = (id) => async (dispatch) => {
    dispatch({
        type: DETAIL_BUSI_REQUEST,
        payload:{id}

      });
    try {
        const { data } = await axiosInterceptorInstance.get(`admin/businesses/${id}`, {
            headers: {
                'Authorization': getAccessToken(),
            }
        })
        dispatch({
            type: DETAIL_BUSI_SUCCESS,
            payload: data?.datas,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: DETAIL_BUSI_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
} 