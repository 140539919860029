// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagi_container{
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .pagi_button{
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagi_button:disabled{
    cursor: not-allowed;
  }`, "",{"version":3,"sources":["webpack://./src/components/pagination/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".pagi_container{\n    padding: 10px;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .pagi_button{\n    padding: 5px 10px;\n    cursor: pointer;\n  }\n  \n  .pagi_button:disabled{\n    cursor: not-allowed;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
