
  import axios from "axios";
export function getAccessToken() {
    return (localStorage.getItem('accessToken') || '').replaceAll(/"/g, '');
  }
  
  export function getAuthorizationHeader() {
    return `Bearer ${getAccessToken()}`;
  }
  
  export function getUserData() {
    return JSON.parse(localStorage.getItem('userData')) || undefined;
  }



// const API = axios.create({
//   baseURL: process.env.REACT_APP_API_UR || "https://dev.api.metgo.app",
//   headers: {
//     "Content-Type": "application/json",
//     // "Content-Type": "multipart/form-data",
//       "Access-Control-Allow-Origin": "*",
//   },
// });


const axiosInterceptorInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  
  
  // Request interceptor
  // axiosInterceptorInstance.interceptors.request.use(
  //   async (config) => {
  //     // Modify the request config here (add headers, authentication tokens)
  //         //const accessToken = JSON.parse(localStorage.getItem("token") || '');
      
  //         const session = await getAuthorizationHeader();

  
  //     // // If token is present add it to request's Authorization Header
  //     if (session) {
  //       if (config.headers) config.headers.token = session;
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     // Handle request errors here
  
  //     return Promise.reject(error);
  //   }
  // );
  // End of Request interceptor
  
  
  
  // Response interceptor
  axiosInterceptorInstance.interceptors.response.use(
    (response) => {
      // Modify the response data here
  
      return response;
    },
    (error) => {
      // Handle response errors here
  
      return Promise.reject(error);
    }
  );
  // End of Response interceptor
  
export default axiosInterceptorInstance;



  