
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux';
import { accessibilitesReducer } from "./store/reducer/accessibilites";
import { addBusiness, businessDetailsReducer, businessReducer, updateBusinessReducer } from "./store/reducer/business";
import { allSubCategoiresReducer, categoiresReducer, subCategoiresReducer } from "./store/reducer/categoires";
import { authorityReducer, dmoReducer } from "./store/reducer/dmo";
import { groupTypeReducer } from "./store/reducer/groupType";
import { addMedia, deleteMediaReducer } from "./store/reducer/media";
import { productsAddReducer, productsDetailsReducer, productsListReducer, productsUpdateReducer } from "./store/reducer/product";
import { userSigninReducer } from "./store/reducer/user";

const intialState = {
    products: [],
    signIn:{
        userInfo: localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null,
    },
};
const reducer = combineReducers({
    initial: intialState,
    signIn: userSigninReducer,
    categoriesList: categoiresReducer,
    subCategoryList: subCategoiresReducer,
    allSubCategoryList:allSubCategoiresReducer,
    dmoList: dmoReducer,
    authorityList:authorityReducer,
    groupTypeList: groupTypeReducer,
    accessList: accessibilitesReducer,
    addBusiness: addBusiness,
    businessList: businessReducer,
    businessDetails: businessDetailsReducer,
    updateBusiness: updateBusinessReducer,
    productsList: productsListReducer,
    addProduct: productsAddReducer,
    addImages: addMedia,
    deleteImage: deleteMediaReducer,
    productDetails: productsDetailsReducer,
    productUpdate: productsUpdateReducer,
    
  })
const store = configureStore({
    reducer
});

export default store;