

import { MdSearch } from "react-icons/md";
import "./search.css";
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from "use-debounce";

const Search = ({ placeholder }) => {
  const searchParams = useSearchParams();
  const history = useNavigate();
  const pathname = window.location.pathname;


  const handleSearch = useDebouncedCallback((e) => {
    const params = new URLSearchParams();
    params.set("page", 1);
    if (e.target.value) {
      e.target.value.length > 2 &&
        params.append("q", e.target.value);
    } else {
      params.delete("q");
    }
     history(`${pathname}?${params}`);
  }, 300);

  return (
    <div className='search_container'>
      <MdSearch />
      <input
        type="text"
        placeholder={placeholder}
        className='search_input'
        onChange={(e) => handleSearch(e)}
      />
    </div>
  );
};

export default Search;