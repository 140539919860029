import React, { useEffect, useState } from 'react'
import {
    useDispatch,
    useSelector
} from 'react-redux'
import { fetchAccessibilities } from '../../store/action/accessibility';
function Accessibility({check, setaccessibilitiesId}) {

    const [checks, setCheck] = useState(check? check : []);
    // const [selectedCat, setselectedCat] = useState(allCat);


    const dispatch = useDispatch()
    const accessibility = useSelector(state => state.accessList); 
    const { loading, error, access } = accessibility;
    useEffect(() => {
        dispatch(fetchAccessibilities())
    },[])
    const toggleSelection = (index) => {
        setCheck((prevSelected) => {
          return prevSelected.includes(index._id)
            ? prevSelected.filter((item) => item !== index._id)
              : [...prevSelected, index._id];

        });
    };
    setaccessibilitiesId(checks);
  return (
    <div className='flex flex-col  p-2 cursor-pointer'  >
        {
            loading ?
            <>loading..</>  
            : error ?
            <span>{error}</span>
            :    
            <span>
            {
                access.map((items, index) => (
                    <span key={index}>
                        {
                            checks.includes(items._id)
                            ?
                            <div 
                                className='bg-gray-200 p-1 rounded-lg m-1 flex items-center '
                                onClick={() => toggleSelection(items)}
                                >
                                <img src={items.icon.iconPath} alt={items.name}/>
                                    {items.name}
                                
                            </div>
                                :
                            <div
                                className='m-1 flex items-center'
                                onClick={() => toggleSelection(items)}
                                >
                                <img src={items.icon.iconPath} alt={items.name}/>
                                {items.name}
                            </div>
                        }
            
                    </span>
                ))
            }
                
            </span>
        }
    </div>
  )
}

export default Accessibility