// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar:hover, .active {
    background-color: #2e374a;
    border-radius: 0.5rem;
}

.sidebar, .image {
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".sidebar:hover, .active {\n    background-color: #2e374a;\n    border-radius: 0.5rem;\n}\n\n.sidebar, .image {\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
