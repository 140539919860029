import React from 'react'
// import { Link } from 'react-router-dom'

function MenuLink({ item }) {
    const pathName = window.location.pathname
  return (
    <a href={item.path} className={
        pathName === item.path ? 'flex sidebar active' : 'sidebar flex '
      } > 
        {item.title}
    </a>
  )
}

export default MenuLink