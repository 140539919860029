import React from 'react'
import {
    MdSearch
} from 'react-icons/md'

import './navbar.css'
function NavBar() {
  return (
    <div className='navcontainers'>
   {/* // <div className={styles.title}>{pathname.split("/").pop()}</div> */}
    <div className='menus'>
      <div className='search'>
        <MdSearch />
        <input type="text" placeholder="Search..." className='inputs' />
      </div>
      <div className='icons'>
       
      </div>
    </div>
  </div>
  )
}

export default NavBar