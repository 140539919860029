import { GROUP_TYPE_LIST_FAILURE, GROUP_TYPE_LIST_REQUEST, GROUP_TYPE_LIST_SUCCESS } from "../constant/groupType";

export const groupTypeReducer = (state = {groupTypes:[], loading:true, error:null}, action) => {
    switch (action.type) {
      case GROUP_TYPE_LIST_REQUEST:
        return {
          loading: true,
        };
      case GROUP_TYPE_LIST_SUCCESS:
        return {
          loading: false,
          groupTypes: action.payload,
        };
  
      case GROUP_TYPE_LIST_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
};