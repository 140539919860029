export const ADD_BUSI_REQUEST = "ADD_BUSI_REQUEST";
export const ADD_BUSI_SUCCESS = "ADD_BUSI_SUCCESS";
export const ADD_BUSI_FAILURE = "ADD_BUSI_FAILURE";
export const ADD_BUSI_RESET = "ADD_BUSI_RESET";

export const LIST_BUSI_REQUEST = "LIST_BUSI_REQUEST";
export const LIST_BUSI_SUCCESS = "LIST_BUSI_SUCCESS";
export const LIST_BUSI_FAILURE = "LIST_BUSI_FAILURE";

export const DETAIL_BUSI_REQUEST = "DETAIL_BUSI_REQUEST";
export const DETAIL_BUSI_SUCCESS = "DETAIL_BUSI_SUCCESS";
export const DETAIL_BUSI_FAILURE = "DETAIL_BUSI_FAILURE";


export const UPDATE_BUSI_REQUEST = "UPDATE_BUSI_REQUEST";
export const UPDATE_BUSI_SUCCESS = "UPDATE_BUSI_SUCCESS";
export const UPDATE_BUSI_FAILURE = "UPDATE_BUSI_FAILURE";
export const UPDATE_BUSI_RESET = "UPDATE_BUSI_RESET";