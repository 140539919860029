import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import './addbusiness.css'
import {useDispatch, useSelector} from 'react-redux'
import { fetchAllSubCategory } from '../../store/action/categories';
import Categories from '../../components/categoires/categories'
import {
    MdArrowDropDown,
    MdArrowDropUp
} from 'react-icons/md'
import SelectStatus from '../../components/status/selectstatus';
import SelectDMO from '../../components/selectDMO/selectdmo';
import GroupType from '../../components/groupType/groupType';
import Accessibility from '../../components/accessibilities/accessibilities';
import { useForm } from "react-hook-form";
import { addNewBusiness } from '../../store/action/business';
import Swal from "sweetalert2";
import { ADD_BUSI_RESET } from '../../store/constant/business';
import ImageSlider from '../../components/imageSlider/imageSlider';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from 'axios';

const MAPBOXURL = process.env.REACT_APP_MAPBOX_SEARCH
const token = `${process.env.REACT_APP_MAPBOX_ACCESS}`;
function AddBusinessPage() {

    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [image, setImage] = useState([])
    const [hideCat, setHideCat] = useState(true)
    const [hideGroupType, setHideGroupType] = useState(true)
    const [hideAcc, setHideAcc] = useState(true)
    const [groupTypesId, setgroupTypesId] = useState([]);
    const [accessibilitiesId, setaccessibilitiesId] = useState([]);
    const [bussCategoriesId, setbussCategoriesId] = useState([]);
    const [bussSubCategoriesId, setbussSubCategoriesId] = useState([]);
    const [contactAddress, setContactAddress] = useState('');
    const [authority, setAuthority] = useState();
    const [status, setStatus] = useState();
    const dispatch = useDispatch()
    const allSubCat = useSelector((state) => state.allSubCategoryList);
    const { allSubCategory } = allSubCat;
    const addbusi = useSelector((state) => state.addBusiness);
    const { loading: bus_Loading, error: bus_error, success } = addbusi;
    useEffect(() => {
        dispatch(fetchAllSubCategory());
            if (success) {
              setValue("name", "");
              setValue("phoneNumber", "");
              setValue("busiEmail", "");
              setValue("description", "");
              setValue("country", "");
              setValue("city", "");
              setValue("location", "");
              setValue("additional_information", "");
              setValue("contactName", "");
              setValue("contactAddress", "");
              setValue("additional_information", "");
              setStatus('');
              setgroupTypesId([]);
              setaccessibilitiesId([]);
              setbussCategoriesId([]);
              setbussSubCategoriesId([])
              setPhone('')
              setCountryCode('')
              setImage([])
              setStatus([])
              setAuthority([])
              Swal.fire("Successfull!", "Bussiness was successfully created", "success");
              dispatch({
                type: ADD_BUSI_RESET,
              });
              window.location.reload();
            }
      if (allSubCategory && bussSubCategoriesId?.length > 0) {
        const cat = allSubCategory.filter(cat => {
          if(bussSubCategoriesId.includes(cat._id))  return cat
        })
        const cats = cat.map(item => {
          return item.bussCategoryId[0]._id
        })
        let outputArray = Array.from(new Set(cats))
        setbussCategoriesId(outputArray)
      }

    }, [dispatch, success, bussSubCategoriesId])

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm();
  
  const handlePhone = (phone, country) => {
    const dialCode = country.dialCode;
    setPhone(phone.split(dialCode)[1])
    setCountryCode(dialCode);
  } 
  const addBusiness = ({name, busiEmail,  description, contactName, additional_information}) => {
    const address = {
          country:country,
          city:city, 
          location:location,
          additional_information:additional_information
      }
    const contactDetails = {
      name: contactName,
      address: contactAddress,
      countryCode: `+${countryCode}`,
      phone:phone
    }
    const phoneNumber = phone;
      dispatch(addNewBusiness(
          name,
          phoneNumber,
          countryCode,
          contactDetails,
          busiEmail,
          address,
          description,
          authority,
          geoLocation,
          status,
          groupTypesId,
          accessibilitiesId,
          bussCategoriesId,
          bussSubCategoriesId,
          image
      ));
    
    console.log(name,
      phoneNumber,
      countryCode,
      contactDetails,
      busiEmail,
      address,
      description,
      authority,
      geoLocation,
      status,
      groupTypesId,
      accessibilitiesId,
      bussCategoriesId,
      bussSubCategoriesId,
      image);
  }
   
  const [suggestPlace, setSuggestPlace] = useState([])
  const [country, setCountry] = useState()
  const [geoLocation, setGeoLocation] = useState({})
  const [city, setCity] = useState()
  const [location, setLocation] = useState();


  let suggestDiv = document.querySelectorAll(".suggestDisplay");
  const searchValue = async (value) => {
    setContactAddress(value);
    suggestDiv[0].style.display = 'block';
    const result = await axios.get(`${MAPBOXURL}${value}.json?access_token=${token}&cachebuster=1566806258853&autocomplete=true&limit=5`)
    setSuggestPlace(result?.data?.features)
  }
  const addContactAddress = (suggest) => {
    setContactAddress(suggest?.place_name);
    suggest?.context.map((context) => {
            if (context.id.includes('country')) {
                setCountry(context.text);
            }

        });
    
    if ( suggestDiv.length !== 0) {
        suggestDiv[0].style.display = 'none';
    } 
    setGeoLocation({
        lat: suggest?.center[1],
        long:suggest?.center[0]
    })

  }  
  return (
    <Layout>
      <div className='addcontainers'>
        <div>
          <div className='infoContainer'>
            <div className='imgContainer'>
              <ImageSlider image={[]} setImages={setImage} update={false}/>
            </div>
          </div>
          <div>
            <div className=
              {
                  hideCat ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
              } >
              <div className='flex justify-between items-center'>
                <span>Categoires and Sub Categoires</span>
                <span  className='cursor-pointer'>
                {
                  hideCat ? 
                    <MdArrowDropDown onClick={()=>setHideCat(false)} style={{fontSize:'25px'}}/>
                  :       
                  <span>
                    <MdArrowDropUp onClick={()=>setHideCat(true)} style={{fontSize:'25px'}}/>
                  </span>
                }
                  
                </span>
              </div>
                <Categories
                  cats={[]}
                  checkValue={[]}
                  allSubCategory={[]}
                  allCategory={[]}
                  setbussCategoriesId={setbussCategoriesId}
                  setbussSubCategoriesId={setbussSubCategoriesId}
                />
            </div>
            <div className={
              hideGroupType ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
            }>
              <div className='flex justify-between items-center'>
                <span>Group Type</span>
                <span  className='cursor-pointer'>
                  {
                      hideGroupType ? 
                  <MdArrowDropDown onClick={()=>setHideGroupType(false)} style={{fontSize:'25px'}}/>
                  :       
                  <span>
                  <MdArrowDropUp onClick={()=>setHideGroupType(true)} style={{fontSize:'25px'}}/>
                  </span>
                  }
                </span>
              </div>
              <div>
                <GroupType check={[]} setgroupTypesId={setgroupTypesId}/>
              </div>
            </div> 
            <div className={
                      hideAcc ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                  }>
                  <div className='flex justify-between items-center'>
                      <span>Accessibility</span>
                      <span  className='cursor-pointer'>
                          {
                              hideAcc ? 
                          <MdArrowDropDown onClick={()=>setHideAcc(false)} style={{fontSize:'25px'}}/>
                          :       
                          <span>
                          <MdArrowDropUp onClick={()=>setHideAcc(true)} style={{fontSize:'25px'}}/>
                          </span>
                          }
                      </span>
                    </div>
                    <div>
                        <Accessibility setaccessibilitiesId={setaccessibilitiesId}  check={[]}/>
                    </div>
            </div> 
          </div>
        </div>
        <div className='formContainer'>
          <form  className='form' onSubmit={handleSubmit(addBusiness)}>
          <span className='flex'>
            <label>Name</label> <span className="text-red-500 text-sm">*</span>
          </span>
          <input type="text" name="name"
            {...register("name", {
              required: "Business Name is required",
            })}  
            />
            {errors.name && (
            <div className="text-red-500">
            {errors.name.message}
            </div>
            )}
            <span className='flex'>
            <label>Business Email</label> <span className="text-red-500 text-sm">*</span>
            </span>
            <input
              type="email"
              name="busiEmail" 
              {...register("busiEmail", {
                required: "Business Email is required",
              })}
            />
            {errors.busiEmail && (
              <div className="text-red-500">
              {errors.busiEmail.message}
              </div>          
            )}
      <span className='flex'>
        <label>Contact Name</label> <span className="text-red-500 text-sm">*</span>
      </span>
      <input
        type="text"
        name="contactName" 
        {...register("contactName", {
          required: "Contact Name is required",
        })}
      />
      {errors.contactName && (
      <div className="text-red-500">
        {errors.contactName.message}
      </div>             
      )}
      <span className='flex'>
        <label>Contact Address</label>
        <span className="text-red-500 text-sm">*</span>
      </span>
      <input
        type="text"
        name="contactAddress" 
        value={contactAddress}
        onChange={(e) => searchValue(e.target.value)}
      />
      <div className='suggestDisplay bg-white rounded-xl text-black'>
            {
              suggestPlace?.map((suggest) => (
                <div className='pt-2'>
                  <div className='shadow cursor-pointer p-1' onClick={() => addContactAddress(suggest)}>
                  {suggest?.place_name}
                  </div>
                </div>
              ))
            }
      </div>
      <span className='flex'>
        <label>Phone Number</label> <span className="text-red-500 text-sm">*</span>
      </span>
      
      <PhoneInput
          inputProps={{
            name: 'phone',
            required: true,
          }}
          style={{color:'black', bgColor:"#151c2c"}}
          enableSearch={true}
          country={'il'}
                  // value={phone}
          onChange={(phone, country) => handlePhone(phone, country)}
          />
          {(phone === "" || phone === undefined) && (
            <div className="text-red-500">
              Phone number is required
            </div>  
                )}
            <span className='flex'>
            <label>Country</label> <span className="text-red-500 text-sm">*</span>
              </span>
              <input type="text" name="country" value={country} onChange={(e)=> setCountry(e.target.value)} />
                <span className='flex'>
                <label>City</label> <span className="text-red-500 text-sm">*</span>
              </span>
            <input type="text" name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                <span className='flex'>
                <label>Location</label> <span className="text-red-500 text-sm">*</span>
              </span>
              <input type="text" name="location" value={location} onChange={(e)=> setLocation(e.target.value)} />
              <label>Additional Information</label>
              <input type="text" name="additional_information"  {...register("additional_information")}/>
              <label>Description</label>
              <textarea type="textarea" name="description" {...register("description", {
              required: "Description  is required",
              })} />
                {errors.description && (
            <div className="text-red-500">
              {errors.description.message}
                    </div>
                    
                )}
              <label>Status</label>
              <SelectStatus name="status" setStatus={setStatus}  ></SelectStatus> 
                {errors.status && (
            <div className="text-red-500">
              {errors.status.message}
                    </div>
                    
                )}
              <label>Select Authority</label>
              <SelectDMO setAuthority={setAuthority}  name="authority"  {...register("authority")} /> 
                {errors.authority && (
            <div className="text-red-500">
              {errors.authority.message}
                    </div>
                    
      )}
      <div>
        {bus_Loading && <span>Loading...</span>}
        {bus_error && <span className='text-red-500'>{ bus_error}</span>}
      </div>
              <input
            type="submit"
            value="Add Business"
            className="
                            w-full
                            rounded-md
                            border
                            py-3
                            bg-green-400
                            text-base 
                            cursor-pointer
                            transition
                  "
          />
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default AddBusinessPage;