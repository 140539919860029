

import axiosInterceptorInstance from '../../auth/auth.helpers'
import { USER_SIGNIN_FAILURE, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS } from '../constant/user';

export const login = (email, password) => async (dispatch) => {

    dispatch({
        type: USER_SIGNIN_REQUEST,
        payload: { email, password },
      });
    try {
        const {data} = await axiosInterceptorInstance.post('/auth/login', {
            email, password
        })
        dispatch({
            type: USER_SIGNIN_SUCCESS,
            payload: data,
          });
        localStorage.setItem('accessToken', JSON.stringify(data.accessToken))
        localStorage.setItem('user', JSON.stringify(data.user))
    } catch (error) {
        dispatch({
            type: USER_SIGNIN_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}