export const SESSION_STORAGE = 'sessionStorage';
export const LOCAL_STORAGE = 'localStorage';

export const getStorageType = () => {
  const storage = localStorage && localStorage.getItem('storage');
  if (storage === LOCAL_STORAGE) return storage;
  return SESSION_STORAGE;
};

export const setStorageType = type => {
  if (type !== LOCAL_STORAGE && type !== SESSION_STORAGE) return false;
  return localStorage.setItem('storage', type);
};

export const getStorage = () => window[getStorageType()];

export function setStorageItem(key, value) {
  if (arguments.length !== 2 || typeof key !== 'string') return false;
  return getStorage().setItem(key, value);
}

export const getStorageItem = (key, def = '') => {
  const storage = getStorage();
  return (storage && storage.getItem(key)) || def;
};

export const clearStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('storage');
  localStorage.removeItem('user');
  sessionStorage.clear();
};
