import React, { useEffect, useState } from 'react'

import {
    useSelector,
    useDispatch
} from 'react-redux'
import { fetchAuthority } from '../../store/action/dmo';


function SelectDMO({ name, setAuthority, defaultValue}) {
    

    const [dmoName, setDmoName] = useState({})
    
    const selectHandel = (e) => {
        e.preventDefault();
        console.log(e.target.value, 'bibash')
        setAuthority(e.target.value)
    
    }
    console.log(defaultValue, "bibash")
    const dispatch = useDispatch()
    const dmos = useSelector(state => state.authorityList); 
    const {loading, error, authority} =dmos
    useEffect(() => {
        
        if (loading) {
            dispatch(fetchAuthority())
            setAuthority(defaultValue);
            } 
        setDmoName(
            authority?.find(item => item._id === defaultValue) ? authority?.find(item => item._id === defaultValue) : 'Select the Authority'
        )
            
        
    }, [dispatch, authority])
    
    
  return (
    <div className='w-full'>
    <select name={name} id={name}  className='w-full' onChange={(e) => selectHandel(e)} >
    {
        dmoName && <option defaultValue={defaultValue? defaultValue : "Select Authority"}>{ dmoName?.name ? dmoName?.name : "Select Authority"}</option>
    }
    {
        loading ?
        <span>loading</span>
        : error ?
            <span>{ error} </span>
        :
        authority.map((item) => (
            <option key={item._id} value={item._id}>
                {item.name}
            </option>
        ))
    }
    </select>
  </div>
  )
}

export default SelectDMO