import { ADD_MEDIA_FAILURE, ADD_MEDIA_REQUEST, ADD_MEDIA_RESET, ADD_MEDIA_SUCCESS, DELETE_MEDIA_FAILURE, DELETE_MEDIA_REQUEST, DELETE_MEDIA_RESET, DELETE_MEDIA_SUCCESS } from "../constant/media";

export const addMedia = (state = { success:false}, action) => {
    switch (action.type) {
      case ADD_MEDIA_REQUEST:
        return {
          loading: true,
        };
      case ADD_MEDIA_SUCCESS:
        return {
          loading: false,
          success: true,
        };
      case ADD_MEDIA_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
      case ADD_MEDIA_RESET:
        return {};
      default:
        return { state };
    }
};


export const deleteMediaReducer = (state = { success:false}, action) => {
  switch (action.type) {
    case DELETE_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case DELETE_MEDIA_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case DELETE_MEDIA_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    case DELETE_MEDIA_RESET:
      return {};
    default:
      return { state };
  }
};

