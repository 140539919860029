// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navcontainers {
    padding: 20px;
    border-radius: 10px;
    background-color: var(--bgSoft);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title {
    color: var(--textSoft);
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .menus {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .search {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #2e374a;
    padding: 10px;
    border-radius: 10px;
  }
  
  .inputs {
    background-color: transparent;
    border: none;
    color: var(--text);
  }
  
  .icons{
    display: flex;
    gap: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;IACtB,iBAAiB;IACjB,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,yBAAyB;IACzB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,SAAS;EACX","sourcesContent":[".navcontainers {\n    padding: 20px;\n    border-radius: 10px;\n    background-color: var(--bgSoft);\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .title {\n    color: var(--textSoft);\n    font-weight: bold;\n    text-transform: capitalize;\n  }\n  \n  .menus {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n  }\n  \n  .search {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    background-color: #2e374a;\n    padding: 10px;\n    border-radius: 10px;\n  }\n  \n  .inputs {\n    background-color: transparent;\n    border: none;\n    color: var(--text);\n  }\n  \n  .icons{\n    display: flex;\n    gap: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
