


 const items = [
     {
        title:'Page',
        list: [
            {
                title: 'Dashboard',
                path: '/',
            },
            {
                title: 'business',
                path: '/dashboard/business',
            },
            {
                title: 'Product',
                path: '/dashboard/products',
            },
            {
                title: 'Users',
                path: '/dashboard/users',
            }
        ]
    }
 ]

export default items;