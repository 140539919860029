import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Categories from '../../../components/categoires/categories';
import Layout from '../../../components/layout'

import {
    MdArrowDropDown,
    MdArrowDropUp,
    MdCloudUpload
} from 'react-icons/md'
import ImageSlider from '../../../components/imageSlider/imageSlider';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../../../store/action/product';
import Swal from 'sweetalert2';
import { ADD_PROD_RESET } from '../../../store/constant/product';
import { fetchAllSubCategory } from '../../../store/action/categories';

function AddProductPage() {

    const pathname = window.location.pathname;
    const id = pathname.split("/").pop()
    const [hideCat, setHideCat] = useState(true)
    const [bussCategoriesId, setbussCategoriesId] = useState([]);
    const [features, setFeatures] = useState([]);
    const [bussSubCategoriesId, setbussSubCategoriesId] = useState([]);
    const [image, setImages] = useState([]);
    const products = useSelector(state => state.addProduct)
    const {loading:up_loading, success, error:up_error } = products;
    const allSubCat = useSelector((state) => state.allSubCategoryList);
    const { allSubCategory } = allSubCat;
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();
    const addProducts = ({name, price, quantity, description})=>{
        console.log(bussSubCategoriesId, name, price, quantity, description);
        const prices = {
            markedPrice:price
        }
        const quantitys = {
            initial:quantity? quantity: 1
        }
        console.log("IMAGE1",image)
        dispatch(addProduct(id, name, prices, quantitys,description, bussCategoriesId, bussSubCategoriesId, features, image))
    }


    useEffect(() => {
        dispatch(fetchAllSubCategory());
        if (success) {
            setValue('name', '')
            setValue('price', '')
            setValue('quantity', '')
            setValue('description', '')
            setbussCategoriesId([])
            setbussSubCategoriesId([])
            setImages([])
            setFeatures([])
            Swal.fire("Successfull!", "Product was successfully created", "success");
            dispatch({
                type:ADD_PROD_RESET
            })
            // window.location.reload();
        }
        if (allSubCategory && bussSubCategoriesId?.length > 0) {
            const cat = allSubCategory.filter(cat => {
              if(bussSubCategoriesId.includes(cat._id))  return cat
            })
            const cats = cat.map(item => {
              return item.bussCategoryId[0]._id
            })
            let outputArray = Array.from(new Set(cats))
            console.log({   });
            setbussCategoriesId(outputArray)
        }
    },[dispatch, setValue, success])
  return (
      <Layout>
          <div className='addcontainers'>
            <div>
                <div className='infoContainer'>
                    <div className='imgContainer'>
                        {/* <div className='tooltip cursor-pointer'>
                        <input type="file" className="filetype cursor-pointer" id="browse" ></input>
                        <div className='tooltiptext'>
                            Upload Image    
                        </div>       
                       </div> */}
                                      {/* <img alt="preview" src={sendFile}/> */}
                          <ImageSlider image={image} setImages={setImages} uploadSuccess={success}/>
                    </div>
                </div>
                <div>
                    <div className=
                    {
                        hideCat ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                    } >
                    <div className='flex justify-between items-center'>
                        <span>Categoires and Sub Categoires</span>
                        <span  className='cursor-pointer'>
                        {
                        hideCat ? 
                        <MdArrowDropDown onClick={()=>setHideCat(false)} style={{fontSize:'25px'}}/>
                        :       
                        <span>
                        <MdArrowDropUp onClick={()=>setHideCat(true)} style={{fontSize:'25px'}}/>
                        </span>
                    }
                        
                        </span>
                    </div>
                        
                        <Categories
                            cats={[]}
                            checkValue={[]}
                            subCategoryCheck={[]}
                            allCategory={[]}
                            setbussCategoriesId={setbussCategoriesId}
                            setbussSubCategoriesId={setbussSubCategoriesId}
                        ></Categories>
                        
                    <div>     
                </div>
                    
                    </div>

                </div>
            
            </div>
            <div className='formContainer'>
                <form  className='form' onSubmit={handleSubmit(addProducts)}>
                    
                    <input hidden type="text" name="id"
                        {...register("id")}
                        
                    />
                    <span className='flex'>
                    <label>Name</label> <span className="text-red-500 text-sm">*</span>
                    </span>
                    <input type="text" name="name" 
                        {...register("name", {
                    required: "Product Name is required",
                        })}
                        
                    />
                    {errors.name && (
                        <div className="text-red-500">
                        {errors.name.message}
                    </div>
                        
                    )}
                    <span className='flex'>
                    <label>Price</label> <span className="text-red-500 text-sm">*</span>
                    </span>
                    <input
                        type="number"
                        name="price"
                        {...register("price", {
                    required: "Product Price is required",
                        })}
                    />
                    {errors.price && (
                        <div className="text-red-500">
                        {errors.price.message}
                    </div>
                        
                    )}
                    <label>Quantity</label>
                        <input defaultValue={1} type="number"  name="quantity" 
                            {...register("quantity", {
                                required: "Quantity is required",
                                
                    })} />
                    {errors.quantity && (
                        <div className="text-red-500">
                        {errors.quantity.message}
                        </div>
                      )}
                      <label>Description</label>
                        <textarea type="textarea"  name="description" 
                            {...register("description", {
                                required: "Description is required",
                                
                    })} />
                    {errors.description && (
                        <div className="text-red-500">
                        {errors.description.message}
                        </div>
                    )}
            <div>
            {up_loading &&<span>Loading...</span>}
            { up_error && <span>{up_error}</span>}
            </div>
                <input
                    type="submit"
                    value="Add Product"
                    className="
                        w-full
                        rounded-md
                        border
                        py-3
                        text-base 
                        cursor-pointer
                        transition"/>
                </form>
            </div>
        </div>
    </Layout>
  )
}

export default AddProductPage