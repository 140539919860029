import React, { useEffect, useState } from 'react'


function SubCategoires({ subCat, check, allSubCat, allCat, setSubCategoires }) {
    
  const [checks, setCheck] = useState(check ? check : []);
  const [selectedSubcat, setSelectSubcat] = useState([]);
  const [selectedCat, setSelectedCat] = useState(allCat);

  const toggleSelection = (index) => {
      setCheck((prevSelected) => {
        return prevSelected.includes(index._id)
          ? prevSelected.filter((item) => item !== index._id)
            : [...prevSelected, index._id];
      });
    
  };
  // console.log("selectedSubcat",subCat)

  setSubCategoires(checks);
  console.log("selectedSubcat",selectedSubcat );

  return (
  <div className='flex flex-col justify-center items-center p-2 cursor-pointer'  >
    <span>
      {
        subCat.map((items, index) => (
          <span key={index}>
            {
              checks.includes(items._id)
              ?
              <div 
                className='bg-gray-200 p-1 rounded-lg m-1'
                onClick={() => toggleSelection(items)}>
                {items.name}
              </div>
              : 
              <div
                className='m-1'
                onClick={() => toggleSelection(items)}>
                {items.name}
              </div>
            }
          </span>
        ))
      }      
    </span>
  </div>
  )
}

export default SubCategoires