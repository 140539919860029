import axiosInterceptorInstance from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { ALL_SUB_CATEGORY_LIST_FAILURE, ALL_SUB_CATEGORY_LIST_REQUEST, ALL_SUB_CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAILURE, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS, SUB_CATEGORY_LIST_FAILURE, SUB_CATEGORY_LIST_REQUEST, SUB_CATEGORY_LIST_SUCCESS } from "../constant/categoires";

export const fetchCategory = () => async (dispatch) => {

    dispatch({
        type: CATEGORY_LIST_REQUEST,

      });
    try {
        const {data} = await axiosInterceptorInstance.get('/admin/buss-category?locale=he')
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: CATEGORY_LIST_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const fetchSubCategory = (id) => async (dispatch) => {

    dispatch({
        type: SUB_CATEGORY_LIST_REQUEST,
        payload: { id}
      });
    try {
        const {data} = await axiosInterceptorInstance.get(`/admin/buss-sub-category/?bussCategoryId=["${id}"]&locale=he`)

        dispatch({
            type: SUB_CATEGORY_LIST_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {

        dispatch({
            type: SUB_CATEGORY_LIST_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}


export const fetchAllSubCategory = () => async (dispatch) => {

  dispatch({
      type: ALL_SUB_CATEGORY_LIST_REQUEST,
      payload: { }
    });
  try {
      const {data} = await axiosInterceptorInstance.get(`/admin/buss-sub-category/?locale=he&limit=100`)

      dispatch({
          type: ALL_SUB_CATEGORY_LIST_SUCCESS,
          payload: data?.datas?.data,
        });
     
  } catch (error) {

      dispatch({
          type: ALL_SUB_CATEGORY_LIST_FAILURE,
          payload:
            error && error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
              ? error?.message
              : error,
        });
  }
}