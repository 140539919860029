import axios from "axios";
import axiosInterceptorInstance, { getAccessToken } from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { ADD_MEDIA_FAILURE, ADD_MEDIA_REQUEST, ADD_MEDIA_SUCCESS, DELETE_MEDIA_FAILURE, DELETE_MEDIA_REQUEST, DELETE_MEDIA_SUCCESS } from "../constant/media";

export const addMedias = (images, refId, reference, businessId) => async (dispatch) => {

    dispatch({
        type: ADD_MEDIA_REQUEST,

      });
    try {

        console.log("bibash",{images});
        let formData = new FormData();
        // formData.append('files', images);
        for (var im = 0; im < images.length; im++) {
            formData.append('image', images[im]);
            console.log("image add",images[im])
        }
        formData.append("reference", reference);
        if (reference === "Business") {
            formData.append("refId", refId);
        }
        else {
            formData.append("refId", refId);
            formData.append("businessId", businessId);
        }
        const { data } = await axiosInterceptorInstance.post('/media', formData, {
            headers: {
                'Authorization': getAccessToken(),
                "Content-Type": "multipart/form-data",
                "type": "formData"

            }
        })
        dispatch({
            type: ADD_MEDIA_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: ADD_MEDIA_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const deleteMedias = (image, refId, reference) => async (dispatch) => {

    dispatch({
        type: DELETE_MEDIA_REQUEST,
        payload: {
            image, refId, reference
        }});
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_API_URL + `media`,
            method: 'delete',
            data: { imageUrl:image, refId, reference },
            headers: {
                'Authorization': getAccessToken(),
            }
          })
        dispatch({
            type: DELETE_MEDIA_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            // clearStorage();
        }
        dispatch({
            type: DELETE_MEDIA_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}