
import { useNavigate, useSearchParams } from "react-router-dom";
import  "./pagination.css";

const Pagination = ({ count }) => {
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pathname = window.location.pathname;
  const q = searchParams.get("q");
  const page = searchParams.get("page") || '1';
  
  const numberPage = parseInt(page);

  const params = new URLSearchParams();
  const ITEM_PER_PAGE = 10;

  if (q?.length > 3) {
    params.append('q', q)
  }

  const hasPrev = ITEM_PER_PAGE * (numberPage - 1) > 0;
  const hasNext = ITEM_PER_PAGE * (numberPage - 1) + ITEM_PER_PAGE < count;

  const handleChangePage = (type) => {
    type === "prev"
      ? params.append("page", (numberPage - 1))
      : params.append("page", (numberPage + 1));
      history(`${pathname}?${params}`);
  };

  return (
    <div className='pagi_container'>
      <button
        className='pagi_button'
        disabled={!hasPrev}
        onClick={() => handleChangePage("prev")}
      >
        Previous
      </button>
      <button
        className='pagi_button'
        disabled={!hasNext}
        onClick={() => handleChangePage("next")}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;