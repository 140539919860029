export const ADD_MEDIA_REQUEST = "ADD_MEDIA_REQUEST";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_FAILURE = "ADD_MEDIA_FAILURE";
export const ADD_MEDIA_RESET = "ADD_MEDIA_RESET";


export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAILURE = "DELETE_MEDIA_FAILURE";
export const DELETE_MEDIA_RESET = "DELETE_MEDIA_RESET";
