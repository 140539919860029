import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Layout from '../../../components/layout';
import Pagination from '../../../components/pagination/pagination';
import Search from '../../../components/search/search';
import {useDispatch, useSelector} from 'react-redux'


import './listbusiness.css'
import { fetchBusiness } from '../../../store/action/business';
function BusinessPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pathname = window.location.pathname;
  const q = searchParams.get('q')|| "";
  const page = searchParams.get('page') || 1;
  const [count, setCount] = useState();
  const dispatch = useDispatch()
  const business = useSelector(state => state.businessList);
  const { loading, error, businesses } = business;

  useEffect(() => {
   
      dispatch(fetchBusiness(page, q));
  },[dispatch, page, q])
  
  // console.log({count}, businesses);
  // if (businesses?.data?.length > 0) {
  //   setCount(businesses?.totalData)
  //   console.log({count})
  // }
 
  return (
    <Layout>
      <div className='list_b_conatainer'>
      <div className='list_b_top'>
        <Search placeholder="Search for a user..." />
        <a href="/dashboard/business/add">
          <button className='list_b_addButton'>Add New</button>
        </a>
        </div>
       { loading ?
        <span> Loading..</span>
              : error ?
                <span>{error} </span> 
                :
      <table className='list_b_table'>
        <thead>
          <tr>
            <td>Name</td>
            <td>Description</td>
            <td>Business Email</td>
            <td>DMO</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {businesses?.data?.map((data) => 
            <tr key={data?._id}>
              <td >
                  {data?.name}
              
              </td>
              <td>{data?.description?.length >= 55 ?
                data?.description?.slice(0, 50) + '...' :
                data?.description
                }</td>
              <td>{data?.busiEmail}</td>
              <td>{data?.dmoId?.name}</td>
              <td>{data?.status }</td>
              <td>
                <div className='list_b_buttons'>
                  <a href={`/dashboard/business/${data?._id}`}>
                    <button className='list_b_button list_b_view'>
                      View
                    </button>
                  </a>
                  <form >
                    <input type="hidden" name="id" value={(data?._id)} />
                    <button className='list_b_button list_b_delete'>
                      Delete
                    </button>
                  </form>
                </div>
              </td>
            </tr>
          )}
        </tbody>    
        
        </table>
        }
        {
          loading ? '' : error ? error :
          <Pagination count={businesses?.totalData} />
     }
    </div>
    </Layout>
  )
}

export default BusinessPage