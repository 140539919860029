import React from 'react'

function ProductCard({ products }) {
    

  return (
    <div>
          {
        products?.data?.map(items => (
          <a href={`product/${items?._id}`}>
            <div className='flex flex-col m-2'>
              <div>
                {
                  items.images.map(img => (
                    <img src={img?.image} alt= "product"></img>
                  ))
                }
              </div>
              <div className='flex flex-col'>
                  <div> Name: {items?.name}</div>
                  <div>{items?.description?.length >= 55 ? items?.description?.slice(0, 50) +'...': items?.description}</div>
                  <div>$ {items?.price?.markedPrice}</div>
              </div>
            </div>
            </a>
            ))
       }
    </div>
  )
}

export default ProductCard