import axiosInterceptorInstance, { getAccessToken } from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { ADD_PROD_FAILURE, ADD_PROD_REQUEST, ADD_PROD_SUCCESS, DETAIL_PROD_FAILURE, DETAIL_PROD_REQUEST, DETAIL_PROD_SUCCESS, LIST_PROD_FAILURE, LIST_PROD_REQUEST, LIST_PROD_SUCCESS, UPDATE_PROD_FAILURE, UPDATE_PROD_REQUEST, UPDATE_PROD_SUCCESS } from "../constant/product";

export const fetchProductList = (id) => async (dispatch) => {

    console.log(id);
    dispatch({
        type: LIST_PROD_REQUEST,
        payload:{id}

      });
    try {
        const { data } = await axiosInterceptorInstance.get(`/admin/products/?businessId=${id}`, {
            headers: {
                'Authorization': getAccessToken(),
            }
        })
        dispatch({
            type: LIST_PROD_SUCCESS,
            payload: data?.datas,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: LIST_PROD_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
} 

export const addProduct = (id, name, price, quantity, description, categories, subCategories, features, image) => async (dispatch) => {
    dispatch({
        type: ADD_PROD_REQUEST,
        payload: {
            name,
            price,
            quantity,
            description,
            categories,
            subCategories,
            features,
            image
        }
    });
    
    console.log("IMAGE12", description)
    try {


        let formData = new FormData();

        for (var i = 0; i < image.length; i++) {
            formData.append('file', image[i]);
        }
        formData.append("name", name);
        formData.append("price[markedPrice]", price.markedPrice);
        formData.append("quantity[initial]", quantity.initial);
        formData.append("description", description);
        for (var l = 0; l < categories.length; l++) {
            formData.append('categories[]', categories[l]);
        }
        for (var j = 0; j < subCategories.length; j++) {
            formData.append('subCategories[]', subCategories[j]);
        }
        for (var k = 0; k < features.length; k++) {
            formData.append('features[]', features[k]);
        }

        const { data } = await axiosInterceptorInstance.post(`/admin/businesses/${id}/products`, formData,
        {
            headers: {
                'Authorization': getAccessToken(),
                "Content-Type": "mutlipart/formdata",
                "type": 'formdata'
            }
        })
        dispatch({
            type: ADD_PROD_SUCCESS,
            payload: data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: ADD_PROD_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const fetchProductDetails = (id) => async (dispatch) => {

    console.log(id);
    dispatch({
        type: DETAIL_PROD_REQUEST,
        payload:{id}

      });
    try {
        const { data } = await axiosInterceptorInstance.get(`/admin/products/${id}`, {
            headers: {
                'Authorization': getAccessToken(),
            }
        })
        dispatch({
            type: DETAIL_PROD_SUCCESS,
            payload: data?.datas,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: DETAIL_PROD_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
} 


export const updateProductAction = (id, businessId, name, price, quantity, description, categories, subCategories, features) => async (dispatch) => {
    console.log( id,
        businessId,
        name,
        price,
        quantity,
        description,
        categories,
        subCategories,
        features)
    dispatch({
        type: UPDATE_PROD_REQUEST,
        payload: {
            id,
            businessId,
            name,
            price,
            quantity,
            description,
            categories,
            subCategories,
            features,
        }
    });
    
    try {


        const { data } = await axiosInterceptorInstance.put(`admin/businesses/${businessId}/products/${id}`, {
            name,
            price,
            quantity,
            description,
            categories,
            subCategories,
            features
        },
        {
            headers: {
                'Authorization': getAccessToken(),
                "Content-Type": "application/json",
            }
        })
        dispatch({
            type: UPDATE_PROD_SUCCESS,
            payload: data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: UPDATE_PROD_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}