import axiosInterceptorInstance from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { GROUP_TYPE_LIST_FAILURE, GROUP_TYPE_LIST_REQUEST, GROUP_TYPE_LIST_SUCCESS } from "../constant/groupType";

export const fetchGroupType = () => async (dispatch) => {

    dispatch({
        type: GROUP_TYPE_LIST_REQUEST,

      });
    try {
        const {data} = await axiosInterceptorInstance.get('/admin/group-type?locale=he')
        dispatch({
            type: GROUP_TYPE_LIST_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: GROUP_TYPE_LIST_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}