import { ADD_PROD_FAILURE, ADD_PROD_REQUEST, ADD_PROD_RESET, ADD_PROD_SUCCESS, DETAIL_PROD_FAILURE, DETAIL_PROD_REQUEST, DETAIL_PROD_SUCCESS, LIST_PROD_FAILURE, LIST_PROD_REQUEST, LIST_PROD_SUCCESS, UPDATE_PROD_FAILURE, UPDATE_PROD_REQUEST, UPDATE_PROD_RESET, UPDATE_PROD_SUCCESS } from "../constant/product";

export const productsListReducer = (state = {products: {}, loading:true, error:null}, action) => {
    switch (action.type) {
      case LIST_PROD_REQUEST:
        return {
          loading: true,
        };
      case LIST_PROD_SUCCESS:
        return {
          loading: false,
          products: action.payload,
        };
  
      case LIST_PROD_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
};
export const productsAddReducer = (state = {success: false}, action) => {
  switch (action.type) {
    case ADD_PROD_REQUEST:
      return {
        loading: true,
      };
    case ADD_PROD_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case ADD_PROD_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
      case ADD_PROD_RESET:
        return {};

    default:
      return state;
  }
};
export const productsDetailsReducer = (state = {products: {}, loading:true, error:null}, action) => {
  switch (action.type) {
    case DETAIL_PROD_REQUEST:
      return {
        loading: true,
      };
    case DETAIL_PROD_SUCCESS:
      return {
        loading: false,
        prodDetails: action.payload,
      };

    case DETAIL_PROD_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const productsUpdateReducer = (state = {success: false}, action) => {
  switch (action.type) {
    case UPDATE_PROD_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PROD_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case UPDATE_PROD_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
      case UPDATE_PROD_RESET:
        return {};

    default:
      return state;
  }
};