export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAILURE = "CATEGORY_LIST_FAILURE";


export const SUB_CATEGORY_LIST_REQUEST = "SUB_CATEGORY_LIST_REQUEST";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST_FAILURE = "SUB_CATEGORY_LIST_FAILURE";


export const ALL_SUB_CATEGORY_LIST_REQUEST = "ALL_SUB_CATEGORY_LIST_REQUEST";
export const ALL_SUB_CATEGORY_LIST_SUCCESS = "ALL_SUB_CATEGORY_LIST_SUCCESS";
export const ALL_SUB_CATEGORY_LIST_FAILURE = "ALL_SUB_CATEGORY_LIST_FAILURE";
