// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containers{
    display: flex;
}
.sidebars{
    flex: 1 1;
    background-color: var(--bgSoft);
    height: 100vh;
}

.mains{
    flex: 4 1;
    height: 100vh;
    overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/components/layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,SAAO;IACP,+BAA+B;IAC/B,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".containers{\n    display: flex;\n}\n.sidebars{\n    flex: 1;\n    background-color: var(--bgSoft);\n    height: 100vh;\n}\n\n.mains{\n    flex: 4;\n    height: 100vh;\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
