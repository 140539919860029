import { ALL_SUB_CATEGORY_LIST_FAILURE, ALL_SUB_CATEGORY_LIST_REQUEST, ALL_SUB_CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAILURE, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS, SUB_CATEGORY_LIST_FAILURE, SUB_CATEGORY_LIST_REQUEST, SUB_CATEGORY_LIST_SUCCESS } from "../constant/categoires";

export const categoiresReducer = (state = {category: [], loading:true, error:null}, action) => {
    switch (action.type) {
      case CATEGORY_LIST_REQUEST:
        return {
          loading: true,
        };
      case CATEGORY_LIST_SUCCESS:
        return {
          loading: false,
          category: action.payload,
        };
  
      case CATEGORY_LIST_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
};
  

export const subCategoiresReducer = (state = {subCategory:[], loading:true, error:null}, action) => {
  switch (action.type) {
    case SUB_CATEGORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case SUB_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        subCategory: action.payload,
      };

    case SUB_CATEGORY_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const allSubCategoiresReducer = (state = {allSubCategory: [] | null, loading:true, error:null}, action) => {
  switch (action.type) {
    case ALL_SUB_CATEGORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_SUB_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        allSubCategory: action.payload,
      };

    case ALL_SUB_CATEGORY_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};