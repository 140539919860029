
import React, { useEffect, useState } from 'react'
import './imageslider.css'

import {
    MdAddAPhoto,
    MdDeleteForever
} from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { addMedia } from '../../store/reducer/media';
import { addMedias, deleteMedias } from '../../store/action/media';
import Swal from 'sweetalert2';
import { ADD_MEDIA_RESET, DELETE_MEDIA_RESET } from '../../store/constant/media';
function ImageSlider({ image, setImages, update, purpose, id, businessId, uploadSuccess }) {

    const [images, setImage] = useState(image);
    const [sendFile, setSendFile] = useState([]);
    let img = images;
   
    const [fileSizeError, setFileSizeError] = useState("");

    const imageAdd = useSelector(state => state.addImages)
    const { success } = imageAdd;
    const imageDel = useSelector(state => state.deleteImage)
    const { success:delete_success } = imageDel;
    let slideIndex = 1;
    function sidePlus(n, check) {
        let i;
        let slides = document.querySelectorAll(".mySlides");
        let dots = document.getElementsByClassName("dot");
        if (!check) {
            if (slides.length !== 0 && dots.length !== 0 ) {
                if (n > slides?.length) { slideIndex = 1 }
                if (n < 1) { slideIndex = slides?.length }
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                for (i = 0; i < dots.length; i++) {
                    dots[i].className = dots[i].className.replace(" active", "");
                }
                slides[slideIndex - 1].style.display = "block";
                dots[slideIndex - 1].className += " active";
            }
        } else {
            if (slides.length !== 0 && dots.length !== 0) {
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                for (i = 0; i < dots.length; i++) {
                    dots[i].className = dots[i].className.replace(" active", "");
                }
                slides[slides.length + 1].style.display = "block";
                dots[slides.length + 1].className += " active";
            }
        }
    } 
    const dispatch = useDispatch();


    function plusSlides(n) {
        sidePlus(slideIndex += n, false);
    }
    function currentSlide(n) {
        sidePlus(slideIndex = n, false);
      }
    useEffect(() => {
        sidePlus(slideIndex, false);

        if (success) {
            Swal.fire("Successfull!", "Image was updated successfully", "success");
            dispatch({
                type:ADD_MEDIA_RESET
            })
        }
        if (delete_success) {
            Swal.fire("Successfull!", "Image was deleted successfully", "success");
            dispatch({
                type:DELETE_MEDIA_RESET
            })
        }
    },[dispatch, success, delete_success])
    
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            //images.push(URL.createObjectURL(event.target.files[0]))
          
            const fileSizeInMB = event.target.files[0].size / (1024 * 1024); // Convert to MB
            const maxSizeInMB = 10; // Maximum allowed file size in MB
            if (fileSizeInMB > maxSizeInMB) {
              setFileSizeError(
                `File size exceeds the maximum limit of ${maxSizeInMB} MB.`
              );
              event.target.value = ""; // Clear the file input
            } else {
                setFileSizeError("");
                img.push({
                    image: URL.createObjectURL(event.target.files[0]),
                    caption:"newimage"
                })
                setImage(img);
                    sendFile.push(event.target.files[0])

                console.log(update, purpose)
                if (update) {
                    if (purpose === 'Business') {
                        dispatch(addMedias([event.target.files[0]], id, purpose))
                    }
                    else if (purpose === 'Product') {
                        dispatch(addMedias([event.target.files[0]], id, purpose, businessId))
                    }
                } else {
                    setImages(sendFile); 
                }
                
                // sidePlus(slideIndex, true);
            }
        }
    }
    const handleDelete = (imgs, index) => {
        img.filter(item => item.image !== imgs.image)
        console.log({img}, imgs)
        console.log(index);
        setImage(images.filter(item => item.image !== imgs.image));
        if (update) {
            if (purpose === 'Business') {
                dispatch(deleteMedias(imgs.image, id, purpose))
            }
            if (purpose === 'Product') {
                dispatch(deleteMedias(imgs.image, id, purpose, businessId))
            }
        } else {
            setImages(sendFile); 
        }
    }
    
  return (
      <div className='imageSlider'>
          {fileSizeError && <span className='text-red-500'>{fileSizeError}</span>}
            <div className="slideshow-container min-h-60">
            <div className="photo" >
                 
                  <label for="image" onChange={onImageChange}>
                      <MdAddAPhoto className='text-2xl hover:text-3xl cursor-pointer bg-slate-500 p-1 rounded-full' type='file' />
                      <input type='file' style={ {display:'none'}} id="image"/>
                 </label>
            </div>
            <>
            {
                images?.map((ima, index) => (
            <>
                <div key={ima.caption} className="mySlides fade">
                    <div className="photo-delete hover:text-4xl"> <MdDeleteForever className='text-2xl hover:text-3xl cursor-pointer bg-slate-500 p-1 rounded-full' onClick={()=>handleDelete(ima, index)}/></div>
                    <div className="flex justify-between">
                        <div className='numbertext '>
                            {index + 1} / {images.length}
                        </div>
                    </div>
                    <img
                        className='image'
                        src={ima.image}
                        alt={'abc'}
                        style={{
                            width: '20rem',
                            maxWidth: '25rem'
                        }}>
                    </img>
                    {/* <div className="text">Caption Text</div> */}
                </div> 
            </>
                    ))
            
            }
            </> 
            <div className="prev" onClick={()=> plusSlides(-1)}>❮</div>
            <div className="next" onClick={()=> plusSlides(+1)}>❯</div>
            </div>
            <div className='text-center'>
                {
                    images.map((img, index) => (
                        <span key={index} className="dot text-center" onClick={() => currentSlide(index+1)}></span> 
                    ))
                }
            </div>
               
    </div>     
  )
}

export default ImageSlider