import axiosInterceptorInstance from "../../auth/auth.helpers";
import { clearStorage } from "../../utils/storage";
import { AUTHORITY_LIST_FAILURE, AUTHORITY_LIST_REQUEST, AUTHORITY_LIST_SUCCESS, DMO_LIST_FAILURE, DMO_LIST_REQUEST, DMO_LIST_SUCCESS } from "../constant/dmo";

export const fetchDMOS = () => async (dispatch) => {

    dispatch({
        type: DMO_LIST_REQUEST,

      });
    try {
        const {data} = await axiosInterceptorInstance.get('/admin/dmo')
        dispatch({
            type: DMO_LIST_SUCCESS,
            payload: data?.datas?.data,
          });
       
    } catch (error) {
        if (error?.response?.data?.statusCode === 401)
        {
            clearStorage();
        }
        dispatch({
            type: DMO_LIST_FAILURE,
            payload:
              error && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
                ? error?.message
                : error,
          });
    }
}

export const fetchAuthority = () => async (dispatch) => {

  dispatch({
      type: AUTHORITY_LIST_REQUEST,

    });
  try {
      const {data} = await axiosInterceptorInstance.get('/authorities')
      dispatch({
          type: AUTHORITY_LIST_SUCCESS,
          payload: data?.datas?.data,
        });
     
  } catch (error) {
      if (error?.response?.data?.statusCode === 401)
      {
          clearStorage();
      }
      dispatch({
          type: AUTHORITY_LIST_FAILURE,
          payload:
            error && error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
              ? error?.message
              : error,
        });
  }
}