import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import Categories from '../../../components/categoires/categories'
import ImageSlider from '../../../components/imageSlider/imageSlider'
import Layout from '../../../components/layout'
import { fetchAllSubCategory } from '../../../store/action/categories'
import { fetchProductDetails, updateProductAction } from '../../../store/action/product'
import { UPDATE_PROD_RESET } from '../../../store/constant/product'

function ProductDetails() {


    const [hideCat, setHideCat] = useState(true)
    const [bussCategoriesId, setbussCategoriesId] = useState([]);
    const [bussSubCategoriesId, setbussSubCategoriesId] = useState([]);
    const [bussSubCategoriesCheck, setbussSubCategoriesCheck] = useState([]);
    const [features, setFeatures] = useState([]);
    const productDetails = useSelector((state) => state.productDetails);
    const { loading, error, prodDetails } = productDetails;
    const allSubCat = useSelector((state) => state.allSubCategoryList);
    const { allSubCategory } = allSubCat;
    const updateProd = useSelector((state) => state.productUpdate);
    const { loading:updat_loading, success:update_success, error:update_error } = updateProd;
    const dispatch = useDispatch();
    const pathname = window.location.pathname;
    const id = pathname.split('/').pop()
    useEffect(() => {
        dispatch(fetchAllSubCategory());
        if (loading) {
            dispatch(fetchProductDetails(id))
        }

        setbussSubCategoriesCheck(
            prodDetails?.bussSubCategoriesId?.map(items => (items._id))
        )
        if (allSubCategory && bussSubCategoriesId?.length > 0) {
            const cat = allSubCategory.filter(cat => {
              if(bussSubCategoriesId.includes(cat._id))  return cat
            })
            const cats = cat.map(item => {
              return item.bussCategoryId[0]._id
            })
            let outputArray = Array.from(new Set(cats))
            console.log({   });
            setbussCategoriesId(outputArray)
        }

    }, [dispatch, bussSubCategoriesId, prodDetails])

    useEffect(() => {
        if (update_success) {
            Swal.fire("Successfull!", "Product was successfully Updated", "success");
            dispatch({
              type: UPDATE_PROD_RESET,
            });
        }
    },[dispatch, update_success])
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm();

    const updateProduct = ({id, businessId, name, price, quantity, description}) => {
        const prices = {
            markedPrice:price
        }
        const quantitys = {
            initial:quantity? quantity: 1
        }
        console.log(id, businessId, name, prices, quantitys, description, bussCategoriesId, bussSubCategoriesId, features);
        dispatch(updateProductAction(id, businessId,  name, prices, quantitys,description, bussCategoriesId, bussSubCategoriesId, features))
    }
  return (
      <Layout>
        {
            loading ? <span>loading...</span> : error ?  <span>{error}</span>:
            <div className='addcontainers'>
                <div>
                    <div className='infoContainer'>
                        <div className='imgContainer'>
                        <ImageSlider
                            image={prodDetails?.images}
                            update={true}
                            purpose={"Product"}
                            id={id} 
                            businessId={prodDetails?.businessId}
                            />
                        </div>
                    </div>
                    <div>
                        <div className=
                            {
                                hideCat ? 'infoContainer hide overflow-hidden m-1' : 'infoContainer m-1'
                            } >
                            <div className='flex justify-between items-center'>
                                <span>Categoires and Sub Categoires</span>
                                <span className='cursor-pointer'>
                                    {
                                        hideCat ?
                                            <MdArrowDropDown onClick={() => setHideCat(false)} style={{ fontSize: '25px' }} />
                                            :
                                            <span>
                                                <MdArrowDropUp onClick={() => setHideCat(true)} style={{ fontSize: '25px' }} />
                                            </span>
                                    }
                
                                </span>
                            </div>
                
                                  {
                                      loading ? "" :
                                          error ? ''
                                              :

                                  
                                      <Categories
                                          cats={[]}
                                          checkValue={[]}
                                          subCategoryCheck={bussSubCategoriesCheck}
                                          allCategory={[]}
                                          setbussCategoriesId={setbussCategoriesId}
                                          setbussSubCategoriesId={setbussSubCategoriesId}
                                      ></Categories>
                                  }
                
                            <div>
                            </div>
            
                        </div>

                    </div>
    
                </div>
                <div className='formContainer'>
                    <form className='form' onSubmit={handleSubmit(updateProduct)}>
            
                        <input hidden type="text" name="id" defaultValue={prodDetails?._id}
                            {...register("id")}
                
                              />
                              <input hidden type="text" name="businessId" defaultValue={prodDetails?.businessId}
                            {...register("businessId")}
                
                        />
                        <span className='flex'>
                            <label>Name</label> <span className="text-red-500 text-sm">*</span>
                        </span>
                        <input type="text" name="name" defaultValue={prodDetails?.name}
                            {...register("name", {
                                required: "Product Name is required",
                            })}
                        />
                        {errors.name && (
                            <div className="text-red-500">
                                {errors.name.message}
                            </div>
                        )}
                        <span className='flex'>
                            <label>Price</label> <span className="text-red-500 text-sm">*</span>
                        </span>
                        <input
                            type="number"
                                  name="price"
                                  defaultValue={prodDetails?.price?.markedPrice}
                            {...register("price", {
                                required: "Product Price is required",
                            })}
                        />
                        {errors.price && (
                            <div className="text-red-500">
                                {errors.price.message}
                            </div>
                        )}
                        <label>Quantity</label>
                            <input
                                type="number"
                                name="quantity"
                                defaultValue={prodDetails?.quantity?.remaining}
                            {...register("quantity", {
                                required: "Quantity is required",
                            })} />
                        {errors.quantity && (
                            <div className="text-red-500">
                                {errors.quantity.message}
                            </div>
                        )}
                        <label>Description</label>
                              <textarea type="textarea" name="description"
                              defaultValue={prodDetails?.description}
                            {...register("description", {
                                required: "Description is required",
                            })} />
                        {errors.description && (
                            <div className="text-red-500">
                                {errors.description.message}
                            </div>
                        )}
                        <div>
                                  {
                                      updat_loading && <span>Loading...</span>}
                       { update_error && <span>{update_error} </span>
                                              
                        }
                        </div>
                        <input
                            type="submit"
                            value="Update Product"
                            className="
                                w-full
                                rounded-md
                                border
                                py-3
                                text-base 
                                cursor-pointer
                                transition"/>
                    </form>
                </div>
            </div>
        }
    </Layout>
  )
}

export default ProductDetails