import React from 'react'
import Layout from '../../components/layout'

function ProductPage() {
  return (
      <Layout>
          productPage
   </Layout>
  )
}

export default ProductPage