// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

:root{
  --bg: #151c2c;
  --bgSoft: #182237;
  --text: white;
  --textSoft: #b7bac1;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: var(--bg);
    color: var(--text);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

a{
    color: inherit;
    text-decoration: none;
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB;;cAEU;AACd;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":["\n\n:root{\n  --bg: #151c2c;\n  --bgSoft: #182237;\n  --text: white;\n  --textSoft: #b7bac1;\n}\n\n*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\nbody{\n    background-color: var(--bg);\n    color: var(--text);\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n}\n\na{\n    color: inherit;\n    text-decoration: none;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
