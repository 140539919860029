import React, { useEffect, useState } from 'react'

export default function Payment() {

    // const response = await fetch(`http://localhost:3001/payment/metgo`)
    // const backendHtmlString = await response.text()
    // console.log(backendHtmlString)

    const [html, setHTML] = useState({__html: ""});
    
    useEffect(() => {
      async function createMarkup() {
          let response;
          
          console.log("bibash");
        response = await fetch(`http://localhost:3001/payment/metgo`)
         const backendHtmlString = await response.text()
  
          console.log(typeof backendHtmlString)
          console.log()
          const newhtml = backendHtmlString.replaceAll(`src="/Tranzila`, `src="https://direct.tranzila.com/Tranzila`)
          const ndNew = newhtml.replaceAll(`href="/Tranzila`, 'href="https://direct.tranzila.com/Tranzila')
          console.log(newhtml)
          return {__html: ndNew};
       }
        createMarkup().then(result => {
            // console.log(result);
            setHTML(result)
       });
    }, []);
    // console.log(html);


  return (
      <>
          {/* {
              html === "" ? <>bibash</> :  <iframe  srcDoc={html.__html} id="tranzila-frame" allowpaymentrequest='true'  name="tranzila" src="https://direct.tranzila.com/" />
          } */}

          {/* { */}
           

          {/* } */}
      <main className="min-h750">
  <div className="container">
    <section id="main-content">
      <div className="row text-center mt-5">
        <div className="col">
          <h1 className="display-3 text-primary">Pay Amount</h1>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <form action="https://direct.tranzila.com/metgo/iframenew.php" target="tranzila" method="POST" novalidate="novalidate" autocomplete="off">
            <div className="form-group">
              <input name="sum" type="hidden" value="200" type="number" id="sum" className="form-control" readonly />
              <span className="text-danger"></span>
            </div>
            <input type="hidden" name="buttonLabel" value="Pay" />
            <input type="hidden" name="lang" value="il" />

            <input type="hidden" name="recur_sum" value="20" />
            <input type="hidden" name="recur_transaction" value="4" />
            <input type="hidden" name="recur_payments" value="12" />
            <input type="hidden" name="success_url_address" value="https://dev.api.metgo.app/payment/success" />
            <input type="hidden" name="fail_url_address" value="https://dev.api.metgo.app/payment/failure" />
            <input type="hidden" name="currency" value="1" />

            <button type="submit" name="submit" classNameName="btn btn-primary mt-3" value="pay">pay</button>
          </form>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <div className="" >
            <iframe id="tranzila-frame" allowpaymentrequest='true'  name="tranzila" src="" style={{width:'100%', height:"100vh"}} ></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>  
    </>
  )
}
