import React from 'react'
import MenuLink from './MenuLink'
import items from './sidebarItems'
import './sidebar.css'

function SideBar() {
  return (
    <div className='container'>
    <div className='flex justify-center'>
       
    </div>
    <ul className='container p-2'>
    {
      items.map((item) => (
            <li key={item.title}>
                <span>{item.title }</span>
               { item.list.map(( list) => (
                <MenuLink key={list.title} item={list}></MenuLink>
                ))
                }
            </li>
        ))
            
  }
    </ul>
    
</div>
  )
}

export default SideBar