import { ADD_BUSI_FAILURE, ADD_BUSI_REQUEST, ADD_BUSI_RESET, ADD_BUSI_SUCCESS, DETAIL_BUSI_FAILURE, DETAIL_BUSI_REQUEST, DETAIL_BUSI_SUCCESS, LIST_BUSI_FAILURE, LIST_BUSI_REQUEST, LIST_BUSI_SUCCESS, UPDATE_BUSI_FAILURE, UPDATE_BUSI_REQUEST, UPDATE_BUSI_RESET, UPDATE_BUSI_SUCCESS } from "../constant/business";

export const addBusiness = (state = {}, action) => {
    switch (action.type) {
      case ADD_BUSI_REQUEST:
        return {
          loading: true,
        };
      case ADD_BUSI_SUCCESS:
        return {
          loading: false,
          success: true,
        };
      case ADD_BUSI_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
      case ADD_BUSI_RESET:
        return {};
      default:
        return { state };
    }
};


export const updateBusinessReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BUSI_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_BUSI_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case UPDATE_BUSI_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_BUSI_RESET:
      return {};
    default:
      return { state };
  }
};
export const businessReducer = (state = {businesses: [], loading:true, error:null}, action) => {
  switch (action.type) {
    case LIST_BUSI_REQUEST:
      return {
        loading: true,
      };
    case LIST_BUSI_SUCCESS:
      return {
        loading: false,
        businesses: action.payload,
      };

    case LIST_BUSI_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const businessDetailsReducer = (state = {busiDetails: {}, loading:true, error:null}, action) => {
  switch (action.type) {
    case DETAIL_BUSI_REQUEST:
      return {
        loading: true,
      };
    case DETAIL_BUSI_SUCCESS:
      return {
        loading: false,
        busiDetails: action.payload,
      };

    case DETAIL_BUSI_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};