import { ACCESSIBILITIES_LIST_FAILURE, ACCESSIBILITIES_LIST_REQUEST, ACCESSIBILITIES_LIST_SUCCESS } from "../constant/accessibilities";

export const accessibilitesReducer = (state = {access:[], loading:true, error:null}, action) => {
    switch (action.type) {
      case ACCESSIBILITIES_LIST_REQUEST:
        return {
          loading: true,
        };
      case ACCESSIBILITIES_LIST_SUCCESS:
        return {
          loading: false,
          access: action.payload,
        };
  
      case ACCESSIBILITIES_LIST_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
};